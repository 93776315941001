import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "container", "slide", "counter", "player", "playOverlay", "thumbnailOverlay"]
  static values = { 
    total: Number,
    current: Number
  }

  connect() {
    this.handleKeyboard = this.handleKeyboard.bind(this)
    this.pauseAllVideos = this.pauseAllVideos.bind(this)
    // Inicializa variáveis de touch
    this.touchStartX = null
    this.touchEndX = null
  }

  openModal(event) {
    const clickedIndex = parseInt(event.currentTarget.dataset.index)
    this.currentValue = clickedIndex
    this.modalTarget.classList.remove("hidden")
    document.body.style.overflow = "hidden"
    document.addEventListener("keydown", this.handleKeyboard)
    this.updateSlide()
  }

  closeModal() {
    this.modalTarget.classList.add("hidden")
    document.body.style.overflow = ""
    document.removeEventListener("keydown", this.handleKeyboard)
    this.pauseAllVideos()
  }

  next() {
    this.pauseAllVideos()
    this.currentValue = (this.currentValue + 1) % this.totalValue
    this.updateSlide()
  }

  previous() {
    this.pauseAllVideos()
    this.currentValue = (this.currentValue - 1 + this.totalValue) % this.totalValue
    this.updateSlide()
  }

  playVideo(event) {
    const currentSlide = this.slideTargets[this.currentValue]
    const player = currentSlide.querySelector('video')
    const overlay = event.currentTarget
    
    player.play()
    overlay.classList.add('hidden')
    
    if (this.hasThumbnailOverlayTarget) {
      this.thumbnailOverlayTarget.classList.add('hidden')
    }
  }

  hidePlayButton(event) {
    const video = event.target
    const slide = video.closest('[data-videos-target="slide"]')
    const overlay = slide.querySelector('[data-videos-target="playOverlay"]')
    const thumbnail = slide.querySelector('[data-videos-target="thumbnailOverlay"]')
    
    overlay.classList.add('hidden')
    if (thumbnail) thumbnail.classList.add('hidden')
  }

  showPlayButton(event) {
    const video = event.target
    const slide = video.closest('[data-videos-target="slide"]')
    const overlay = slide.querySelector('[data-videos-target="playOverlay"]')
    const thumbnail = slide.querySelector('[data-videos-target="thumbnailOverlay"]')
    
    overlay.classList.remove('hidden')
    if (thumbnail && video.currentTime === 0) thumbnail.classList.remove('hidden')
  }

  updateSlide() {
    this.slideTargets.forEach((slide, index) => {
      slide.classList.toggle("hidden", index !== this.currentValue)
    })
    this.counterTarget.textContent = `${this.currentValue + 1} / ${this.totalValue}`
    
    // Reset play button visibility for new slide
    const currentSlide = this.slideTargets[this.currentValue]
    const player = currentSlide.querySelector('video')
    const overlay = currentSlide.querySelector('[data-videos-target="playOverlay"]')
    const thumbnail = currentSlide.querySelector('[data-videos-target="thumbnailOverlay"]')
    
    overlay.classList.remove('hidden')
    if (thumbnail) thumbnail.classList.remove('hidden')
  }

  pauseAllVideos() {
    this.playerTargets.forEach(video => {
      if (!video.paused) {
        video.pause()
      }
    })
  }

  handleKeyboard(event) {
    if (event.key === "Escape") this.closeModal()
    if (event.key === "ArrowRight") this.next()
    if (event.key === "ArrowLeft") this.previous()
  }

  // Handlers de touch
  touchStart(event) {
    // Guarda a posição inicial do toque
    this.touchStartX = event.touches[0].clientX
  }

  touchMove(event) {
    // Atualiza a posição final do toque
    this.touchEndX = event.touches[0].clientX
  }

  touchEnd() {
    // Se não tiver as posições de toque, não faz nada
    if (!this.touchStartX || !this.touchEndX) return
    
    const diff = this.touchStartX - this.touchEndX
    const minSwipeDistance = 50 // Distância mínima para considerar como swipe

    if (Math.abs(diff) > minSwipeDistance) {
      if (diff > 0) {
        // Swipe para a esquerda
        this.next()
      } else {
        // Swipe para a direita
        this.previous()
      }
    }
    
    // Reset das variáveis de touch
    this.touchStartX = null
    this.touchEndX = null
  }
}
