// app/javascript/controllers/element_removal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add("transition-opacity", "duration-[3000ms]", "ease-linear")

    setTimeout(() => {
      this.element.classList.add("opacity-0")
      setTimeout(() => {
        this.element.remove()
      }, 3000)
    }, 2000)
  }
}
