import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

class Upload {
  static completedUploads = 0
  static totalUploads = 0

  constructor(file, element, contentTarget, blankStateTarget) {
    this.directUpload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this)
    this.element = element
    this.contentTarget = contentTarget
    this.blankStateTarget = blankStateTarget
    this.createProgressBar()
  }

  createProgressBar() {
    this.progressElement = document.createElement("div")
    this.progressElement.className = "p-4"

    const fileName = document.createElement("div")
    fileName.className = "mb-1 text-sm font-medium text-gray-700 dark:text-white"
    fileName.textContent = this.directUpload.file.name

    const progressBar = document.createElement("div")
    progressBar.className = "w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700"

    this.progressBarFill = document.createElement("div")
    this.progressBarFill.className = "bg-primary-600 h-1.5 rounded-full dark:bg-primary-400 transition-all duration-300"
    this.progressBarFill.style.width = "0%"

    progressBar.appendChild(this.progressBarFill)
    this.progressElement.appendChild(fileName)
    this.progressElement.appendChild(progressBar)

    if (this.contentTarget) {
      if (this.blankStateTarget) {
        this.blankStateTarget.classList.add('hidden')
      }
      this.contentTarget.appendChild(this.progressElement)
    }
  }

  updateProgress(percentage) {
    this.progressBarFill.style.width = `${percentage}%`
    if (percentage === 100) {
      this.progressBarFill.className = "bg-green-600 h-1.5 rounded-full dark:bg-green-400"
    }
  }

  updateProgressBarId(blob) {
    if (this.progressElement) {
      this.progressElement.id = `blob_${blob.signed_id}`
    }
  }

  process() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          console.error("Upload error:", error)
          this.handleError()
          reject(error)
        } else {
          this.updateProgressBarId(blob)
          this.createArchive(blob)
          Upload.completedUploads++
          resolve(blob)
        }
      })
    })
  }

  createArchive(blob) {
    const token = document.querySelector('meta[name="csrf-token"]').content
    const url = this.element.dataset.directUploadCreatePathValue
    const attributeName = this.element.dataset.directUploadAttributeNameValue || 'photo'

    const body = {}
    body[attributeName] = {
      blob_signed_id: blob.signed_id
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': "application/json",
        'X-CSRF-Token': token,
        'Accept': "text/vnd.turbo-stream.html"
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.text()
    })
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(error => {
      console.error("Error creating archive:", error)
      this.handleError()
    })
  }

  handleError() {
    this.progressBarFill.className = "bg-red-600 h-1.5 rounded-full dark:bg-red-400"
    const errorMessage = document.createElement("div")
    errorMessage.className = "text-sm text-red-600 dark:text-red-400 mt-1"
    errorMessage.textContent = "Erro no upload. Tente novamente."
    this.progressElement.appendChild(errorMessage)
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => {
      const percentage = (event.loaded / event.total) * 100
      this.updateProgress(percentage)
    })
  }
}

export default class extends Controller {
  static targets = ["input", "content", "blankState"]
  static values = {
    createPath: String,
    attributeName: { type: String, default: 'photo' }
  }

  async acceptFiles(event) {
    event.preventDefault()
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files

    if (files.length === 0) return

    Upload.completedUploads = 0
    Upload.totalUploads = files.length

    try {
      const uploadPromises = Array.from(files).map(file => {
        const upload = new Upload(
          file,
          this.element,
          this.contentTarget,
          this.hasBlankStateTarget ? this.blankStateTarget : null
        )
        return upload.process()
      })

      await Promise.all(uploadPromises)
    } catch (error) {
      console.error("Failed to upload:", error)
    }
  }
}
