import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "container", "slide", "counter"]
  static values = { 
    total: Number,
    current: Number
  }

  connect() {
    this.handleKeyboard = this.handleKeyboard.bind(this)
  }

  openModal(event) {
    const clickedIndex = parseInt(event.currentTarget.dataset.index)
    this.currentValue = clickedIndex
    this.modalTarget.classList.remove("hidden")
    document.body.style.overflow = "hidden"
    document.addEventListener("keydown", this.handleKeyboard)
    this.updateSlide()
  }

  closeModal() {
    this.modalTarget.classList.add("hidden")
    document.body.style.overflow = ""
    document.removeEventListener("keydown", this.handleKeyboard)
  }

  next() {
    this.currentValue = (this.currentValue + 1) % this.totalValue
    this.updateSlide()
  }

  previous() {
    this.currentValue = (this.currentValue - 1 + this.totalValue) % this.totalValue
    this.updateSlide()
  }

  updateSlide() {
    this.slideTargets.forEach((slide, index) => {
      slide.classList.toggle("hidden", index !== this.currentValue)
    })
    this.counterTarget.textContent = `${this.currentValue + 1} / ${this.totalValue}`
  }

  handleKeyboard(event) {
    if (event.key === "Escape") this.closeModal()
    if (event.key === "ArrowRight") this.next()
    if (event.key === "ArrowLeft") this.previous()
  }

  // Suporte a gestos touch
  touchStart(event) {
    this.touchStartX = event.touches[0].clientX
  }

  touchMove(event) {
    this.touchEndX = event.touches[0].clientX
  }

  touchEnd() {
    if (!this.touchStartX || !this.touchEndX) return
    
    const diff = this.touchStartX - this.touchEndX
    if (Math.abs(diff) > 50) { // Mínimo de movimento para considerar como swipe
      if (diff > 0) {
        this.next()
      } else {
        this.previous()
      }
    }
    
    // Reset touch values
    this.touchStartX = null
    this.touchEndX = null
  }
}
