import { Controller } from "@hotwired/stimulus"
import { Modal } from 'flowbite'

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.modal = new Modal(this.element, {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
        const frame = document.getElementById('modal_content')
        if (frame) frame.innerHTML = ''
      }
    })
  }

  open() {
    this.modal.show()
  }

  close() {
    this.modal.hide()
  }
}
