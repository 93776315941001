import { Controller } from "@hotwired/stimulus"
import WaveSurfer from "wavesurfer.js"

export default class extends Controller {
  static targets = ["waveform", "playButton", "pauseButton", "timer", "loader"]
  
  initialize() {
    this.isPlaying = false
    this.wavesurfer = null

    document.addEventListener('turbo:before-cache', () => {
      if (this.wavesurfer) {
        this.wavesurfer.destroy()
        this.wavesurfer = null
      }
    })
  }

  connect() {
    if (this.wavesurfer) return

    this.showLoader()

    if (this.hasWaveformTarget) {
      this.waveformTarget.style.opacity = '0.3'
    }

    this.initializeWithCache()
  }

  disconnect() {
    // Não faz nada no disconnect
    // A limpeza acontece no turbo:before-cache
  }

  async initializeWithCache() {
    if (this.wavesurfer) return

    const audioUrl = this.element.dataset.audioUrl

    try {
      const cache = await caches.open('wavesurfer-cache')
      const cachedResponse = await cache.match(audioUrl)

      if (cachedResponse) {
        const audioBlob = await cachedResponse.blob()
        this.createWaveSurferFromBlob(audioBlob)
      } else {
        const response = await fetch(audioUrl)
        const audioBlob = await response.clone().blob()
        await cache.put(audioUrl, new Response(audioBlob))
        this.createWaveSurferFromBlob(audioBlob)
      }
    } catch (error) {
      console.error('Erro no cache:', error)
      this.createWaveSurfer()
    }
  }

  createWaveSurferFromBlob(blob) {
    if (this.wavesurfer) return

    const audioUrl = URL.createObjectURL(blob)

    this.wavesurfer = WaveSurfer.create({
      container: this.waveformTarget,
      barWidth: 2,
      barRadius: 3,
      height: 32,
      hideScrollbar: true,
      waveColor: "#10b981",
      progressColor: "#059669",
      barGap: 2,
      normalize: true,
      responsive: true
    })

    this.setupWaveSurferEvents()
    this.wavesurfer.load(audioUrl)
  }

  createWaveSurfer() {
    if (this.wavesurfer) return

    this.wavesurfer = WaveSurfer.create({
      container: this.waveformTarget,
      barWidth: 2,
      barRadius: 3,
      height: 32,
      hideScrollbar: true,
      waveColor: "#10b981",
      progressColor: "#059669",
      url: this.element.dataset.audioUrl,
      barGap: 2,
      normalize: true,
      responsive: true
    })

    this.setupWaveSurferEvents()
  }

  setupWaveSurferEvents() {
    if (!this.wavesurfer) return

    this.wavesurfer.on('ready', () => {
      const duration = this.wavesurfer.getDuration()
      const totalTime = this.formatTime(duration)
      if (this.hasTimerTarget) {
        this.timerTarget.textContent = totalTime
      }
      this.hideLoader()
    })

    this.wavesurfer.on('error', (error) => {
      console.error('Erro no WaveSurfer:', error)
      this.hideLoader()
    })

    this.wavesurfer.on('finish', () => {
      if (this.hasPauseButtonTarget && this.hasPlayButtonTarget) {
        this.pauseButtonTarget.classList.add('hidden')
        this.playButtonTarget.classList.remove('hidden')
      }
      this.wavesurfer.seekTo(0)
      this.isPlaying = false
      
      if (this.hasTimerTarget) {
        const duration = this.wavesurfer.getDuration()
        const totalTime = this.formatTime(duration)
        this.timerTarget.textContent = totalTime
      }
    })

    this.wavesurfer.on('audioprocess', () => {
      if (this.isPlaying && this.hasTimerTarget) {
        const currentTime = this.wavesurfer.getCurrentTime()
        const formattedTime = this.formatTime(currentTime)
        this.timerTarget.textContent = formattedTime
      }
    })

    this.wavesurfer.on('seeking', () => {
      if (this.hasTimerTarget) {
        const currentTime = this.wavesurfer.getCurrentTime()
        const formattedTime = this.formatTime(currentTime)
        this.timerTarget.textContent = formattedTime
      }
    })
  }

  showLoader() {
    if (this.hasWaveformTarget && this.hasLoaderTarget) {
      this.waveformTarget.style.opacity = '0.3'
      this.loaderTarget.classList.remove('hidden')
    }
  }

  hideLoader() {
    if (this.hasWaveformTarget && this.hasLoaderTarget) {
      this.waveformTarget.style.opacity = '1'
      this.loaderTarget.classList.add('hidden')
    }
  }

  play() {
    if (this.wavesurfer) {
      this.wavesurfer.play()
      if (this.hasPlayButtonTarget && this.hasPauseButtonTarget) {
        this.playButtonTarget.classList.add('hidden')
        this.pauseButtonTarget.classList.remove('hidden')
      }
      this.isPlaying = true
    }
  }

  pause() {
    if (this.wavesurfer) {
      this.wavesurfer.pause()
      if (this.hasPlayButtonTarget && this.hasPauseButtonTarget) {
        this.pauseButtonTarget.classList.add('hidden')
        this.playButtonTarget.classList.remove('hidden')
      }
      this.isPlaying = false
      
      if (this.hasTimerTarget) {
        const duration = this.wavesurfer.getDuration()
        const totalTime = this.formatTime(duration)
        this.timerTarget.textContent = totalTime
      }
    }
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return [minutes, seconds]
      .map(unit => unit < 10 ? `0${unit}` : unit)
      .join(":")
  }
}
