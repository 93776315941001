import { Controller } from "@hotwired/stimulus"
import WaveSurfer from "wavesurfer.js"
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js"
import { DirectUpload } from "@rails/activestorage"

class AudioUpload {
  constructor(blob, element, progressTarget) {
    this.csrfToken = this.getCSRFToken()

    const file = new File([blob], "recorded_audio.webm", { 
      type: "audio/webm",
      lastModified: new Date().getTime()
    })

    const url = '/rails/active_storage/direct_uploads'
    const token = this.csrfToken
    const uploadUrl = `${url}?authenticity_token=${encodeURIComponent(token)}`

    this.directUpload = new DirectUpload(file, uploadUrl, this)
    this.element = element
    this.progressTarget = progressTarget
  }

  getCSRFToken() {
    const tokenElement = document.querySelector('meta[name="csrf-token"]')
    if (!tokenElement) {
      throw new Error('Token CSRF não encontrado')
    }
    const token = tokenElement.getAttribute('content')
    if (!token) {
      throw new Error('Token CSRF não encontrado')
    }
    return token
  }

  updateProgress(percentage) {
    if (percentage === 100) {
      this.progressTarget.innerHTML = `
        <div class="flex items-center justify-center w-full">
          <div class="text-sm text-green-600 dark:text-green-400">Upload concluído!</div>
        </div>
      `
    } else {
      this.progressTarget.innerHTML = `
        <div class="flex items-center justify-center w-full">
          <div class="text-sm text-gray-600 dark:text-gray-400">Upload em progresso: ${Math.round(percentage)}%</div>
        </div>
      `
    }
  }

  process() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          console.error("Erro no upload:", error)
          this.handleError()
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }

  handleError() {
    this.progressTarget.innerHTML = `
      <div class="flex items-center justify-center w-full">
        <div class="text-sm text-red-600 dark:text-red-400">Erro no upload. Tente novamente.</div>
      </div>
    `
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    xhr.setRequestHeader("X-CSRF-Token", this.csrfToken)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.setRequestHeader("Accept", "application/json")
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", event => {
      const percentage = (event.loaded / event.total) * 100
      this.updateProgress(percentage)
    })

    xhr.setRequestHeader("X-CSRF-Token", this.csrfToken)
    xhr.setRequestHeader("Accept", "application/json")
  }
}

export default class extends Controller {
  static targets = [
    "recordButtonWrapper", "recordButton", "stopButton",
    "form", "pauseButton", "resumeButton",
    "playButton", "pausePlayButton",
    "cancelButton", "submitButton", "uploadProgress",
    "progress", "waveform", "fileUpload"
  ]

  static values = {
    messageId: String
  }

  connect() {
    this.createWaveSurfer()
    this.audioBlob = null
    this.csrfToken = this.getCSRFToken()
    this.isPlaying = false
    this.lastPlayPosition = 0
  }

  getCSRFToken() {
    const tokenElement = document.querySelector('meta[name="csrf-token"]')
    if (!tokenElement) {
      throw new Error('Token CSRF não encontrado')
    }
    const token = tokenElement.getAttribute('content')
    if (!token) {
      throw new Error('Token CSRF não encontrado')
    }
    return token
  }

  createWaveSurfer() {
    if (this.wavesurfer) this.wavesurfer.destroy()
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformTarget,
      barWidth: 3,
      barRadius: 5,
      hideScrollbar: true,
      waveColor: "#10b981",
      progressColor: "#059669",
    })
  }

  async initializeRecording() {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true })

      this.record = this.wavesurfer.registerPlugin(
        RecordPlugin.create({
          renderRecordedAudio: false,
          scrollingWaveform: false,
          continuousWaveform: true,
          continuousWaveformDuration: 60
        })
      )
      this.record.on("record-progress", this.updateProgress.bind(this))

      await this.startRecording()
    } catch (error) {
      console.error('Acesso negado ao Microfone:', error)
      this.recordButtonTarget.disabled = true
      this.recordButtonTarget.textContent = "Microfone não disponível"
    }
  }

  async startRecording() {
    this.recordButtonWrapperTarget.classList.add("hidden")
    this.stopButtonTarget.classList.remove("hidden")
    this.pauseButtonTarget.classList.remove("hidden")
    this.cancelButtonTarget.classList.remove("hidden")
    await this.record.startRecording()
  }

  stopRecording() {
    this.record.on("record-end", this.handleRecordEnd.bind(this))
    this.record.stopRecording()
    this.stopButtonTarget.classList.add("hidden")
    this.pauseButtonTarget.classList.add("hidden")
    this.resumeButtonTarget.classList.add("hidden")
  }

  pauseRecording() {
    this.record.pauseRecording()
    this.pauseButtonTarget.classList.add("hidden")
    this.resumeButtonTarget.classList.remove("hidden")
  }

  resumeRecording() {
    this.record.resumeRecording()
    this.resumeButtonTarget.classList.add("hidden")
    this.pauseButtonTarget.classList.remove("hidden")
  }

  startPlayback() {
    if (this.playbackWavesurfer) {
      this.playbackWavesurfer.play()
      this.playButtonTarget.classList.add("hidden")
      this.pausePlayButtonTarget.classList.remove("hidden")
      this.isPlaying = true
      this.updateTimer()
    }
  }

  pausePlayback() {
    if (this.playbackWavesurfer) {
      this.playbackWavesurfer.pause()
      this.pausePlayButtonTarget.classList.add("hidden")
      this.playButtonTarget.classList.remove("hidden")
      this.isPlaying = false
      this.lastPlayPosition = this.playbackWavesurfer.getCurrentTime()
      
      const duration = this.playbackWavesurfer.getDuration()
      const minutes = Math.floor(duration / 60)
      const seconds = Math.floor(duration % 60)
      const totalTime = [minutes, seconds]
        .map(v => v < 10 ? `0${v}` : v)
        .join(":")
      this.progressTarget.textContent = totalTime
    }
  }

  updateProgress(time) {
    const minutes = Math.floor((time % 3600000) / 60000)
    const seconds = Math.floor((time % 60000) / 1000)
    const formattedTime = [minutes, seconds]
      .map(v => v < 10 ? `0${v}` : v)
      .join(":")
    this.progressTarget.textContent = formattedTime
  }

  updateTimer() {
    if (!this.isPlaying) return

    const currentTime = this.playbackWavesurfer.getCurrentTime()
    const minutes = Math.floor(currentTime / 60)
    const seconds = Math.floor(currentTime % 60)
    const formattedTime = [minutes, seconds]
      .map(v => v < 10 ? `0${v}` : v)
      .join(":")
    this.progressTarget.textContent = formattedTime

    requestAnimationFrame(() => this.updateTimer())
  }

  resetRecorder() {
    if (this.wavesurfer) this.wavesurfer.destroy()
    if (this.playbackWavesurfer) this.playbackWavesurfer.destroy()

    this.createWaveSurfer()
    this.recordButtonWrapperTarget.classList.remove("hidden")
    this.stopButtonTarget.classList.add("hidden")
    this.pauseButtonTarget.classList.add("hidden")
    this.resumeButtonTarget.classList.add("hidden")
    this.playButtonTarget.classList.add("hidden")
    this.pausePlayButtonTarget.classList.add("hidden")
    this.cancelButtonTarget.classList.add("hidden")
    this.submitButtonTarget.classList.add("hidden")
    this.uploadProgressTarget.classList.add("hidden")
    this.progressTarget.textContent = "00:00"
    this.isPlaying = false
    this.lastPlayPosition = 0
    
    if (this.fileUploadTarget) {
      this.fileUploadTarget.value = ''
    }
  }

  handleRecordEnd(blob) {
    this.audioBlob = blob
    const recordedUrl = URL.createObjectURL(blob)
    this.createPlaybackWaveSurfer(recordedUrl)
    this.playButtonTarget.classList.remove("hidden")
    this.cancelButtonTarget.classList.remove("hidden")
    this.submitButtonTarget.classList.remove("hidden")
  }

  createPlaybackWaveSurfer(url) {
    if (this.wavesurfer) this.wavesurfer.destroy()
    if (this.playbackWavesurfer) this.playbackWavesurfer.destroy()
    
    this.playbackWavesurfer = WaveSurfer.create({
      container: this.waveformTarget,
      barWidth: 3,
      barRadius: 5,
      hideScrollbar: true,
      waveColor: "#10b981",
      progressColor: "#059669",
      url: url,
    })

    this.playbackWavesurfer.on('ready', () => {
      const duration = this.playbackWavesurfer.getDuration()
      const minutes = Math.floor(duration / 60)
      const seconds = Math.floor(duration % 60)
      const totalTime = [minutes, seconds]
        .map(v => v < 10 ? `0${v}` : v)
        .join(":")
      this.progressTarget.textContent = totalTime
    })

    this.playbackWavesurfer.on('finish', () => {
      this.pausePlayButtonTarget.classList.add("hidden")
      this.playButtonTarget.classList.remove("hidden")
      this.playbackWavesurfer.seekTo(0)
      this.isPlaying = false
      this.lastPlayPosition = 0
      
      const duration = this.playbackWavesurfer.getDuration()
      const minutes = Math.floor(duration / 60)
      const seconds = Math.floor(duration % 60)
      const totalTime = [minutes, seconds]
        .map(v => v < 10 ? `0${v}` : v)
        .join(":")
      this.progressTarget.textContent = totalTime
    })
  }

  handleFileSelect(event) {
    const file = event.target.files[0]
    if (!file) return
    
    this.audioBlob = file
    const audioUrl = URL.createObjectURL(file)
    
    this.createPlaybackWaveSurfer(audioUrl)
    this.showPlaybackControls()
  }

  showPlaybackControls() {
    this.recordButtonWrapperTarget.classList.add("hidden")
    this.playButtonTarget.classList.remove("hidden")
    this.cancelButtonTarget.classList.remove("hidden")
    this.submitButtonTarget.classList.remove("hidden")
  }

  hideAllControls() {
    this.recordButtonWrapperTarget.classList.add("hidden")
    this.stopButtonTarget.classList.add("hidden")
    this.pauseButtonTarget.classList.add("hidden")
    this.resumeButtonTarget.classList.add("hidden")
    this.playButtonTarget.classList.add("hidden")
    this.pausePlayButtonTarget.classList.add("hidden")
    this.cancelButtonTarget.classList.add("hidden")
    this.submitButtonTarget.classList.add("hidden")
  }

  async uploadAudio() {
    if (!this.audioBlob) return

    // Esconde todos os controles durante o upload
    this.hideAllControls()
    this.uploadProgressTarget.classList.remove("hidden")
    
    // Adiciona classes para centralizar a div de progresso
    this.uploadProgressTarget.classList.add("flex", "justify-center", "items-center", "w-full", "py-4")

    try {
      const upload = new AudioUpload(
        this.audioBlob,
        this.element,
        this.uploadProgressTarget
      )

      const blob = await upload.process()
      await this.createAudioMessage(blob.signed_id)
    } catch (error) {
      console.error('Erro ao fazer upload do áudio:', error)
      // Em caso de erro, mostra os controles novamente
      this.showPlaybackControls()
      this.uploadProgressTarget.classList.add("hidden")
      // Remove as classes de centralização
      this.uploadProgressTarget.classList.remove("flex", "justify-center", "items-center", "w-full", "py-4")
    }
  }

  async createAudioMessage(signedId) {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = 'audio[blob_signed_id]'
    hiddenField.value = signedId
    this.formTarget.appendChild(hiddenField)
    this.formTarget.submit()
  }
}
